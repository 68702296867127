// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

declare var require: any;
export const environment = {
  production: false,
  appID: '83a9dbbf876c44c490c0cb719d916c7d',
  appPackageName: 'com.aspevo.dbushapp',
  apiHostUrl: 'https://core-dev.aspevo.com/api/v3/',
  projectId: 124,
  pageConfig: {
    size: 10,
    options: [10,25,50]
  },
  auth: {
    clientID: '8vAy9djYM6QSOXlkzyFZx6L3XZtrsdROHKTyFZZR',
    username: 'admin@dbush.com',
    password: '12345678'
  },
  firebase: {
    apiKey: "AIzaSyBUQo0-ZeDcj7SpFJzdWUWS84vQMVDiLWk",
    authDomain: "dunhambush-c9572.firebaseapp.com",
    projectId: "dunhambush-c9572",
    storageBucket: "dunhambush-c9572.appspot.com",
    messagingSenderId: "862510862963",
    appId: "1:862510862963:web:040003f72b782552e61006",
    measurementId: "G-1PSYTF3TV1"
  },
  appVersion: require('../../package.json').version + '-dev',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
