import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable } from "rxjs";

import { RestApiService } from "./rest-api.service";
import { Paging, Project } from "../models/base.model";

@Injectable({
    providedIn: 'root'
})
export class ApiProjectService extends RestApiService {


    public apiGetObjects(params: any): Observable<any> {
        let url = this.getBaseUrl() + 'projects/';
        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });

        return this.http.get<any>(url, { params: httpParams }).pipe(
            map(item => {
                let page: Paging = Paging.parse(item.page);

                let data = item.data.map((inItem: any) => Project.parse(inItem));

                return { 'data': data, 'page': page }
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiGetObject(id: any, params: any = {}): Observable<Project> {
        let url = this.getBaseUrl() + `projects/${id}/`;
        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.get<Project>(url, { params: httpParams }).pipe(
            map(item => {
                return Project.parse(item);
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPostObject(payload: any, params: any = {}): Observable<Project> {
        let url = this.getBaseUrl() + `projects/`;

        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });

        return this.http.post<Project>(url, payload, { params: httpParams }).pipe(
            map(item => {
                return Project.parse(item);
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPutObject(id: any, payload: any, params: any = {}): Observable<Project> {
        const url = this.getBaseUrl() + `projects/${id}/`;
        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.put<Project>(url, payload, { params: httpParams }).pipe(
            map(item => {
                return Project.parse(item);
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiDeleteObject(id: any, params: any): Observable<any> {
        const url = this.getBaseUrl() + `projects/${id}/`;
        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.delete<any>(url, { params: httpParams }).pipe(
            // map(item => {
            //   return new Company(item);
            // }),
            catchError(this.errorHandler.handleError),
        );
    }

}
