/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, from, lastValueFrom, map, mergeMap, Observable, of, Subject } from 'rxjs';
import { ApiAuthService } from '../apis/api-auth.service';
import { ApiMeService } from '../apis/api-me.service';
import { DataStoreService } from './data-store.service';
import { Auth, signInWithCustomToken } from '@angular/fire/auth';
import { ApiError, Company, Project, Site, User } from '../models/base.model';
import { AuthCredential } from '../models/auth-data.model';
import { environment } from 'environments/environment';
import { ApiProjectService } from '../apis/api-project.service';
import { AbnormalResponsibleObject } from '../models/app.model';

@Injectable({
  providedIn: 'root'
})
/**
 * User data provider to hold user specific data like session and cache
 */
export class UserDataService {
  private readonly KeyUser = 'user:profile';
  private readonly KeyCompanyActive = 'company:active';
  private readonly KeyProjectActive = 'project:active';
  private auth: Auth = inject(Auth);

  private user: User;
  private company: Company;
  private project: Project;
  private validateCompanyEventSubject = new Subject<boolean>();
  public validateCompanyEvent = this.validateCompanyEventSubject.asObservable();
  
  private abResp: AbnormalResponsibleObject;

  constructor(
    private _storage: DataStoreService,
    private apiAuthSvc: ApiAuthService,
    private apiMeSvc: ApiMeService,
    private apiProjectSvc: ApiProjectService
  ) { }

  async init() {

  }

  hasRole(role: string) {
    let company = this.getCompany();

    if (company && company.teamRoles) {
      let index = company.teamRoles.findIndex(x => x.role === role);
      if (index > -1) {
        return true;
      }
    }

    return false;
  }

  hasRoles(roles: string[]) {
    let valid = false;

    let company = this.getCompany();

    if (company && company.teamRoles) {
      for (let index = 0; index < roles.length; index++) {
        const role = roles[index];
        let matchIndex = company.teamRoles.findIndex(x => x.role === role);
        if (matchIndex > -1) {
          valid = true;
          break;
        }
      }
    }

    return valid;
  }
  // user
  getUser(): User {

    if (!this.user) {
      let jsonObj = this._storage.getObject(this.KeyUser);
      this.user = User.parse(jsonObj);
    }
    return this.user;
  }

  setUser(obj: User): boolean {
    this.user = obj;
    return this._storage.setObject(this.KeyUser, obj.rawJson);
  }

  getProject(): Project {
    if (!this.project) {
      let jsonObj = this._storage.getObject(this.KeyProjectActive);
      if (jsonObj) {
        this.project = Project.parse(jsonObj);
      }
    }

    return this.project;
  }

  setProject(obj: Project): boolean {
    this.project = obj;
    return this._storage.setObject(this.KeyProjectActive, obj.rawJson);
  }

  getCompany(): Company {
    if (!this.company) {
      let jsonObj = this._storage.getObject(this.KeyCompanyActive);
      if (jsonObj) {
        this.company = Company.parse(jsonObj);
      }
    }

    return this.company;
  }

  setCompany(obj: Company): boolean {
    this.company = obj;
    return this._storage.setObject(this.KeyCompanyActive, obj.rawJson);
  }

  //api
  // firebaseLogin(payload: any, params: any = {}): Observable<AuthCredential> {
  //   return this.apiAuthSvc.apiPostFirebaseAuth(payload, params).pipe(
  //     map((auth: AuthCredential) => {
  //       this.authCredential = auth;
  //       this.setAuthCred(auth.rawJson);
  //       // this.loginEventSubject.next(auth);
  //       return auth;
  //     })
  //   );
  // }

  // firebaseCustomLogin(payload: any, params: any) {
  //   this.apiAuthSvc.apiPostFirebaseCustomToken(payload, params).subscribe({
  //     next: res => {
  //       let customToken = res['custom_token'];
  //       signInWithCustomToken(this.auth, customToken)
  //         .then((userCredential) => {
  //           console.log('signInWithCustomToken:', userCredential);
  //         })
  //         .catch((error) => {
  //           const errorCode = error.code;
  //           const errorMessage = error.message;

  //           console.error('signInWithCustomToken errorCode:', errorCode);
  //           console.error('signInWithCustomToken errorMessage:', errorMessage);

  //         });
  //     },
  //     complete: () => {

  //     },
  //     error: err => {
  //       console.error('firebaseCustomLogin error:', err);
  //     }
  //   })
  // }

  apiGetMyProfile(params: any): Observable<User> {
    return this.apiMeSvc.apiGetMe(params).pipe(
      map((res: User) => {
        this.setUser(res);
        return res;
      })
    );
  }

  apiGetProject(params: any): Observable<Project> {
    const id = environment.projectId;
    if (!params.fields) {
      params['fields'] = 'id,uuid,title,description,app_settings';
    }
    return this.apiProjectSvc.apiGetObject(id, params).pipe(
      map((res: Project) => {
        this.setProject(res);
        return res;
      })
    )
  }

  apiGetCompany(): Observable<Company> {
    const params = {}

    return this.apiMeSvc.apiGetMyCompanies(params).pipe(
      map((res) => {
        if (res.data.length > 0) {
          let instance: Company = res.data[0];
          this.setCompany(instance);
        }
        return res;
      })
    );
  }

  //const
  getAbnormalResponsible(): AbnormalResponsibleObject {
    if (!this.abResp) {
      const project = this.getProject();
      
      const constantObj = project.getAppSettingValue('constant', environment.appPackageName);
      let data = constantObj['ab_resp_list'] ? constantObj['ab_resp_list'] : [];

      this.abResp = AbnormalResponsibleObject.parse(data);
    }
    
    return this.abResp;
  }

  // Event
  checkValidateCompany() {
    this.validateCompanyEventSubject.next(true);
  }

  //global
  async clearStorage() {

    this.user = null;
    this.company = null;
    this._storage.remove(this.KeyUser);
    this._storage.remove(this.KeyCompanyActive);
    this._storage.remove('filter:abResponsible');

  }

}
