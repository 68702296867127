import { HttpErrorResponse } from "@angular/common/http";
import { environment } from "environments/environment";

/** Model: Common */
export class ApiError extends Error {
    public code: number = 0;
    public description: string | null = null;
    public codeR: string | null = null;
    public httpErrorResponse: HttpErrorResponse | null = null;
    constructor(message: string, code: number, description: string, codeR: string, httpErrorResponse: HttpErrorResponse | null) {
        super(message);
        this.code = code;
        this.description = description;
        this.codeR = codeR;
        this.httpErrorResponse = httpErrorResponse;
    }

}

/** Model: Common */
export class Address {
    street: string;
    city: string;
    country: string;
    postcode: string;
    state: string;
    venue: string;
    constructor(data = null) { }

    static parse(data: any): Address | null | undefined {
        if (!data) {
            return null;
        }

        let obj = new Address();
        obj.street = data.street;
        obj.city = data.city;
        obj.country = data.country;
        obj.postcode = data.postcode;
        obj.state = data.state;
        obj.venue = data.venue;
        return obj;
    }

}

export class Paging {
    constructor(
        public count: number,
        public cur_page: number,
        public page_size: number,
        public total_page: number,
        public next_page_num: number,
        public prev_page_num: number,
        public next: string,
        public prev: string,

    ) { }

    static parse(item: any): Paging {

        return new Paging(
            item.count,
            item.cur_page,
            item.page_size,
            item.total_page,
            item.next_page_num,
            item.prev_page_num,
            item.next,
            item.prev,
        );
    }

    static parse_db(item: any): Paging {

        return new Paging(
            item.count,
            item.cur_page,
            item.page_size,
            item.total_page,
            item.next_page_num,
            item.prev_page_num,
            item.next,
            item.prev,
        );
    }
}

export abstract class RawJsonDict {
    rawJson: any;

    constructor(data: any) {
        this.rawJson = data;
    }
}

export abstract class ContactFunction extends RawJsonDict {
    contacts: any;

    constructor(data: any) {
        super(data);
    }

    getContactPhone(type: string): string | null | undefined {
        try {
            return this.contacts['phones'][type];
        } catch (error) {
            return null;
        }
    }

    getContactEmail(type: string): string | null | undefined {
        try {
            return this.contacts['emails'][type];
        } catch (error) {
            return null;
        }
    }

    getContactSocial(key: string) {
        try {
            return this.contacts['socials'][key];
        } catch (error) {

        }
        return '';
    }
}

/** Model: The App */
export class TheApp extends RawJsonDict {
    id: any;
    uuid: string;
    title: string;
    meta: any;

    constructor(data = null) {
        super(data);
    }

    static parse(data: any): TheApp {

        let instance = new TheApp(data);
        instance.id = data.id;
        instance.uuid = data.uuid;
        instance.title = data.title;

        try {
            instance.meta = data.meta;
        } catch (error) {
            instance.meta = {}
        }

        return instance;
    }
}

/** Model: Tag */
export class Tag {
    id: any;
    name: string;
    type: string;
    meta: any;

    static parse(data: any): Tag {

        let instance = new Tag();
        instance.id = data.id;
        instance.name = data.name;
        instance.type = data.type;

        try {
            instance.meta = data.meta;
        } catch (error) {
            instance.meta = {}
        }

        return instance;
    }
}

/** Model: Industry */
export class Industry {
    id: any;
    uuid: string;
    title: string;
    description: string;
    meta: any;
    parent: Industry | null | undefined;

    static parse(data: any): Industry {

        let instance = new Industry();
        instance.id = data.id;
        instance.uuid = data.uuid;
        instance.title = data.title;
        instance.description = data.description;

        try {
            instance.meta = data.meta;
        } catch (error) {
            instance.meta = {}
        }

        try {
            instance.parent = Industry.parse(data.parent);
        } catch (error) {
            // instance.parent = null;
        }
        return instance;
    }

    displayName() {
        let names: any[] = [];
        if (this.parent) {
            names.push(this.parent.title);
        }
        names.push(this.title);

        return names.join(' - ');
    }
}

/** Model: User */
export class User extends RawJsonDict {
    id: number;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    profile: Profile;

    constructor(data = null) {
        super(data)
    }

    static parse(data: any): User | any {
        if (!data) {
            return null;
        }

        let obj = new User(data);

        obj.id = data.id;
        obj.firstName = data.first_name;
        obj.lastName = data.last_name;
        obj.email = data.email;
        obj.username = data.username;

        try {
            obj.profile = Profile.parse(data.profile);
            if (obj.profile.avatarPhoto) {
                obj.avatar = obj.profile.avatarPhoto.file;
            }
        } catch (error) {
            // obj.profile = null;
        }
        return obj;
    }

    getName() {
        let names: any[] = [];
        if (this.firstName) {
            names.push(this.firstName);
        }

        if (this.lastName) {
            names.push(this.lastName);
        }

        return names.join(' ');
    }

    set avatar(value: string) { }

    get avatar(): string {
        if (this.profile.avatarPhoto) {
            return this.profile.avatarPhoto.file;
        }

        return null;
    }

}

export class Profile extends ContactFunction {
    mobile: string;
    dob: string;
    bio: string;
    gender: string;

    address: Address;
    workInfo: any;
    meta: any;

    avatarPhoto: MediaObject;
    coverPhoto: MediaObject;

    constructor(data = null) {
        super(data);
    }

    needLinkFirebase() {
        let need_link = this.meta['need_ext_user'];
        return need_link;
    }
    static parse(data: any): Profile | null | undefined {
        if (!data) {
            return null;
        }

        let obj = new Profile();
        obj.dob = data.dob;
        obj.bio = data.bio;
        obj.gender = data.gender;
        obj.address = Address.parse(data.address);
        obj.contacts = data.contacts;
        obj.workInfo = data.work_info;
        obj.meta = data.meta;

        try {
            obj.avatarPhoto = MediaObject.parse(data.avatar_photo);
        } catch (error) {

        }

        try {
            obj.coverPhoto = MediaObject.parse(data.cover_photo);
        } catch (error) {

        }

        return obj;
    }

    getValueFromWorkInfo(key: string) {
        try {
            return this.workInfo[key];
        } catch (error) {

        }
        return '';
    }

}

/** Model: Company */
export class Company extends ContactFunction {
    id: number;
    uuid: string;
    slug: string;
    title: string;
    description: string;
    website: string;
    address: Address | null | undefined;

    logoPhoto: MediaObject | null | undefined;
    coverPhoto: MediaObject | null | undefined;
    industries: Industry[] = [];
    teamRoles: Team[] = [];
    locale: any;
    meta: any;
    created: Date;
    lastModified: Date;
    constructor(data = null) {
        super(data);
    }

    static parse(data: any): Company | null | undefined {
        if (!data) {
            return null;
        }

        let obj: Company = new Company(data);
        obj.id = data.id;
        obj.uuid = data.uuid;
        obj.title = data.title;
        obj.description = data.description;
        obj.slug = data.slug;
        obj.website = data.website;
        obj.locale = data.locale;
        obj.meta = data.meta;
        obj.contacts = data.contacts;

        obj.address = Address.parse(data.address);

        try {
            obj.logoPhoto = MediaObject.parse(data.logo_photo);
        } catch (error) {

        }

        try {
            obj.coverPhoto = MediaObject.parse(data.cover_photo);
        } catch (error) {

        }

        try {
            obj.teamRoles = data.roles.map((x: any) => Team.parse(x));
        } catch (error) {

        }

        try {
            obj.industries = data.industries.map((x: any) => Industry.parse(x))
        } catch (error) {

        }

        try {
            obj.created = new Date(data.created);
        } catch (error) {
        }

        try {
            obj.lastModified = new Date(data.last_modified);
        } catch (error) {
        }

        return obj;
    }

    getCTA(key: string) {
        try {
            return this.meta.cta[key];
        } catch (error) {

        }
        return '';
    }

    getIndustriesForTagForm() {
        let data = this.industries.map(x => {
            return { value: x, display: x.title }
        });

        return data;
    }

}

export class Team {
    id: number;
    role: string;
    meta: any;
    user: User;
    company: Company;
    created: Date;
    lastModified: Date;

    // constructor(data = null) {
    //     super(data);
    // }

    static parse(data: any): Team {
        if (!data) {
            return null;
        }

        let obj: Team = new Team();
        obj.id = data.id;
        obj.role = data.role;
        obj.meta = data.meta;
        try {
            obj.user = User.parse(data.user);
        } catch (error) { }

        try {
            obj.company = Company.parse(data.company);
        } catch (error) { }

        try {
            obj.created = new Date(data.created);
        } catch (error) {
        }

        try {
            obj.lastModified = new Date(data.last_modified);
        } catch (error) {
        }

        return obj;
    }

    getName() {
        try {
            return `${this.user?.firstName} ${this.user?.lastName}`;
        } catch (error) {

        }
        return ''
    }

    getRoleLocale() {
        switch (this.role) {
            case 'OWN':
                return 's.teamRoles.owner';
                break;
            case 'STA':
                return 's.teamRoles.admin';
                break;
            case 'STF':
                return 's.teamRoles.staff';
                break;
            case 'SUV':
                return 's.teamRoles.supervisor';
                break;
            default:
                return '-'
                break;
        }
    }
}

/** Model: Media */
export class MediaObject extends RawJsonDict {
    id: number;
    title: string;
    description: string;
    mime: string;
    file: string;
    fileSize: number;
    user: User | null | undefined;
    company: Company | null | undefined;
    uploadBy: User | null | undefined;
    meta: any;
    created: Date;
    lastModified: Date;

    constructor(data: any = null) {
        super(data);
    }

    static parse(data: any): MediaObject | null | undefined {
        if (!data) {
            return null;
        }

        let obj = new MediaObject();
        obj.id = data.id;
        obj.title = data.title;
        obj.description = data.description;
        obj.mime = data.mime_type;
        obj.file = data.file;
        obj.fileSize = data.file_size;
        obj.meta = data.meta;

        try {
            obj.created = new Date(data.created);
        } catch (error) {
        }

        try {
            obj.lastModified = new Date(data.last_modified);
        } catch (error) {
        }

        try {
            obj.company = Company.parse(data.company);
        } catch (error) { }

        try {
            obj.user = User.parse(data.user);
        } catch (error) { }

        try {
            obj.uploadBy = User.parse(data.upload_by);
        } catch (error) { }


        return obj;
    }


    isImage() {
        if (this.mime) {
            let imageType = ['jpeg', 'jpg', 'png', 'gif', 'bmp'];
            return imageType.includes(this.mime.split('/')[1]);
        }
        return false;
    }

    getFileData() {
        let FileData: FileData = {
            uploaded: true,  // to indicate upload or not
            loading: false,
            isImage: this.isImage(),
            previewUrl: this.file, // preview url or data
            name: this.title, // name, most likely is file name
            alt: this.description, // caption
            media: this,
            processing: false, //local resize or compressing
            status: 'ready',
            fileSize: this.fileSize
        }
        return FileData;
    }

    getThumbnailObj(type: string) {
        if (!this.isImage()) {
            return null;
        }

        try {
            return this.meta.thumbnails[type];
        } catch (error) {
            return null;
        }
    }

    getThumbnailUrl(type: string) {
        let obj = this.getThumbnailObj(type);
        if (!obj) {
            return null;
        }

        return obj.url;
    }
}

//file upload holder
export interface FileData {
    file?: File; // file object
    uploaded?: boolean;  // to indicate upload or not
    loading?: boolean;
    isImage?: boolean;
    previewUrl?: any; // preview url or data
    name?: string; // name, most likely is file name
    alt?: string; // caption
    height?: number;
    width?: number;
    media?: MediaObject;
    thumbnailMediumFile?: ThumbnailData;
    thumbnailLargeFile?: ThumbnailData;
    processing?: boolean; //local resize or compressing
    status?: string; //pending, compress, thumbnail, upload,ready, complete
    tags?: any;
    fileSize?: number;
    id?: any;
}

export interface ThumbnailData {
    file?: File;
    url?: string;
    uploaded?: boolean;
    height?: number;
    width?: number;
    uploadName?: string;
}

/** Model: Project */
export class Project extends ContactFunction {
    id: number;
    uuid: string;
    slug: string;
    title: string;
    description: string;
    website: string;
    timezone: string;
    startTS: number;
    endTS: number;
    startDate: Date;
    endDate: Date;
    expirationTS: number;

    type: string;
    status: string;
    visibility: string;

    badgeFormats: any;
    appSettings: any;
    meta: any;
    statMeta: any;
    created: Date;
    lastModified: Date;

    address: Address | null | undefined;
    app: TheApp | null | undefined;
    logoPhoto: MediaObject | null | undefined;
    coverPhoto: MediaObject | null | undefined;
    owners: Company[] = [];
    // hashtags: [];
    industries: Industry[] = [];

    constructor(data = null) {
        super(data);
    }

    static parse(data: any): Project | null | undefined {
        if (!data) {
            return null;
        }

        let obj: Project = new Project(data);
        obj.id = data.id;
        obj.uuid = data.uuid;
        obj.title = data.title;
        obj.description = data.description;
        obj.slug = data.slug;
        obj.website = data.website;
        obj.timezone = data.timezone;
        obj.startTS = data.start_ts;
        obj.endTS = data.end_ts;
        obj.expirationTS = data.expiration_ts;
        obj.type = data.type;
        obj.status = data.status;
        obj.visibility = data.visibility;
        obj.contacts = data.contacts;

        obj.badgeFormats = data.badge_formats;
        obj.appSettings = data.app_settings
        obj.meta = data.meta;
        obj.statMeta = data.stat_meta;
        try {
            obj.startDate = new Date(obj.startTS * 1000);
        } catch (error) { }
        try {
            obj.endDate = new Date(obj.endTS * 1000);
        } catch (error) { }

        try {
            obj.address = Address.parse(data.address);
        } catch (error) { }
        try {
            obj.address = Address.parse(data.address);
        } catch (error) { }

        try {
            obj.app = TheApp.parse(data.app);
        } catch (error) { }

        try {
            obj.logoPhoto = MediaObject.parse(data.logo_photo);
        } catch (error) { }

        try {
            obj.coverPhoto = MediaObject.parse(data.cover_photo);
        } catch (error) { }

        try {
            obj.owners = data.owners.map(x => Company.parse(x));
        } catch (error) { }

        try {
            obj.industries = data.industries.map(x => Industry.parse(x));
        } catch (error) { }

        try {
            obj.created = new Date(data.created);
        } catch (error) {

        }

        try {
            obj.lastModified = new Date(data.last_modified);
        } catch (error) {

        }

        return obj;
    }

    getDisplayStatus() {
        switch (this.status) {
            case 'publish':
                return 'share.status.publish';
                break;
            case 'draft':
            default:
                return 'share.status.draft';
                break;
        }
    }

    getDisplayVisibility() {
        switch (this.visibility) {
            case 'private':
                return 'share.visibilityStatus.private';
                break;
            case 'public':
            default:
                return 'share.visibilityStatus.public';
                break;
        }
    }

    getDates(): Date[] {
        let startDate = new Date(this.startDate);
        startDate.setHours(0, 0, 0, 0);
        let endDate = new Date(this.endDate);
        endDate.setHours(0, 0, 0, 0)
        endDate.setDate(endDate.getDate() + 1);

        let daySeconds = 86400000;

        let startTS = startDate.getTime();
        let endTS = startTS + daySeconds;


        let dates: any[] = [];
        let valid = true;

        while (valid) {
            dates.push({
                startTS: startTS,
                endTS: endTS,
                startDate: new Date(startTS),
                endDate: new Date(endTS)
            })

            startTS = startTS + daySeconds;
            endTS = startTS + daySeconds;

            if (startTS >= endDate.getTime()) {
                valid = false;
            }
        }

        return dates;
    }

    getOwner() {
        try {
            return this.owners[0];
        } catch (error) {

        }
        return null;
    }

    getAppSetting(packageName: string = environment.appPackageName) {
        return this.appSettings[packageName];
    }

    getAppSettingValue(key: string, packageName: string = environment.appPackageName) {
        const appSetting = this.getAppSetting();

        return appSetting[key];
    }
}

/** Model: Site */
export class Site extends ContactFunction {
    id: number;
    title: string;
    description: string;
    expirationTS: number;
    meta: any;
    created: Date;
    lastModified: Date;

    company: Company | null | undefined;
    project: Project | null | undefined;
    saved: boolean;
    constructor(data = null) {
        super(data);
    }

    static parse(data: any): Site | null | undefined {
        if (!data) {
            return null;
        }

        let obj: Site = new Site(data);
        obj.parseData(data)

        return obj;
    }

    parseData(data: any) {
        console.log('site parseData');
        this.id = data.id;
        this.title = data.title;
        this.description = data.description;
        this.expirationTS = data.expiration_ts;
        this.contacts = data.contacts;

        this.meta = data.meta;

        try {
            this.company = Company.parse(data.company);
        } catch (error) {
            console.log('site company parse error');
        }

        try {
            this.project = Project.parse(data.project);
        } catch (error) { }

        try {
            this.created = new Date(data.created);
        } catch (error) {
        }

        try {
            this.lastModified = new Date(data.last_modified);
        } catch (error) {
        }

    }

}

/** Model: Catalog */
export class Catalog extends RawJsonDict {
    id: number;
    uuid: string;
    slug: string;
    title: string;
    description: string;
    summary: string;
    publishStatus: string;
    type: string;
    ownerType: string;
    coverPhoto: MediaObject;
    galleries: MediaObject[];
    attachments: MediaObject[];
    parent: Catalog | null | undefined;
    user: User | null | undefined;
    company: Company | null | undefined;
    site: Site | null | undefined;
    hashtags: Tag[];
    industries: Industry[];
    locale: any;
    meta: any;
    created: Date;
    lastModified: Date;
    saved: boolean;

    static parse(data: any): Catalog {
        let instance = new Catalog(data);
        instance.parseData(data);
        return instance;
    }

    parseData(data: any) {
        this.id = data.id;
        this.uuid = data.uuid;
        this.slug = data.slug;
        this.title = data.title;
        this.description = data.description;
        this.summary = data.summary;
        this.publishStatus = data.publish_status;
        this.type = data.type;
        this.ownerType = data.owner_type ? data.owner_type : '';
        this.meta = data.meta ? data.meta : {};
        this.locale = data.locale ? data.locale : {};

        try {
            this.user = User.parse(data.company);
        } catch (error) { }

        try {
            this.company = Company.parse(data.company);
        } catch (error) { }

        try {
            this.site = Site.parse(data.site);
        } catch (error) { }

        try {
            this.coverPhoto = MediaObject.parse(data.cover_photo);
        } catch (error) { }


        try {
            this.galleries = data.galleries.map(x => MediaObject.parse(x));
        } catch (error) { }

        try {
            this.attachments = data.attachments.map(x => MediaObject.parse(x));
        } catch (error) { }

        try {
            this.parent = Catalog.parse(data.parent);
        } catch (error) { }

        try {
            this.hashtags = data.hashtags.map(x => Tag.parse(x));
        } catch (error) { }

        try {
            this.industries = data.industries.map(x => Industry.parse(x));
        } catch (error) { }

        this.created = new Date(data.created);

        this.lastModified = new Date(data.last_modified);

    }

    getPublishStatusName() {
        switch (this.publishStatus) {
            case 'publish':
                return 'share.status.published'
                break;

            default:
                return 'share.status.draft'
                break;
        }
    }
}

/** Model: Report */
// reports
export class Report {
    id: number;
    uuid: string;
    title: string;
    description: string;
    type: string;
    ownerType: string;
    company: Company;
    project: Project;
    app: TheApp;
    lastProcessDate: Date;
    meta: any;
    created: Date;
    lastModified: Date;

    static parse(data: any): Report {
        let instance = new Report();
        instance.id = data.id;
        instance.uuid = data.uuid;
        instance.title = data.title;
        instance.description = data.description;
        instance.type = data.type;
        instance.ownerType = data.owner_type;

        instance.meta = data.meta;
        instance.created = new Date(data.created);
        instance.lastModified = new Date(data.last_modified);

        try {
            instance.company = Company.parse(data.company);
        } catch (error) {

        }

        try {
            instance.project = Project.parse(data.project);
        } catch (error) {

        }

        try {
            instance.app = TheApp.parse(data.app);
        } catch (error) {

        }

        try {
            instance.lastProcessDate = new Date(instance.meta.last_ts * 1000);

        } catch (error) {

        }

        return instance;
    }
}

export class ReportWidget {
    id: number;
    uuid: string;
    type: string;
    slug: string;
    report: Report;
    snapshot: any;
    meta: any;
    created: Date;
    lastModified: Date;

    static parse(data: any): ReportWidget {
        let instance = new ReportWidget();
        instance.id = data.id;
        instance.uuid = data.uuid;
        instance.type = data.type;
        instance.slug = data.slug;
        instance.snapshot = data.snapshot;
        instance.meta = data.meta;
        instance.created = new Date(data.created);
        instance.lastModified = new Date(data.last_modified);

        try {
            instance.report = Report.parse(data.report);
        } catch (error) { }


        return instance;
    }
}