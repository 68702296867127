import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable } from "rxjs";
import { RestApiService } from "./rest-api.service";
import { AuthCredential, EmailCodeVerifyItem, EmailRequestCodeItem, EmailVerifyItem, ExternalUser } from "../models/auth-data.model";

@Injectable({
    providedIn: 'root'
})
export class ApiAuthService extends RestApiService {

    // constructor(public http: HttpClient) {
    //     super(http);
    // }
    
    public apiPostEmailLogin(payload: any, params: any): Observable<any> {
        let url = this.getBaseUrl() + `auth/token/`;

        payload['grant_type'] = 'password';
        payload['client_id'] = this.getClientID();
        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<AuthCredential>(url, payload, { params: httpParams }).pipe(
            map(item => {
                
                return AuthCredential.parse(item);
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPostLogout(payload: any, params: any): Observable<any> {
        let url = this.getBaseUrl() + `auth/logout/`;
        payload['client_id'] = this.getClientID();
        
        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<any>(url, payload, { params: httpParams }).pipe(
            
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPostEmailVerification(payload: any, params: any): Observable<EmailVerifyItem> {
        let url = this.getBaseUrl() + `auth/email/verification/`;

        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<EmailVerifyItem>(url, payload, { params: httpParams }).pipe(
            map(item => {
                return EmailVerifyItem.parse(item);
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPostEmailPreSignUp(payload: any, params: any): Observable<EmailRequestCodeItem> {
        let url = this.getBaseUrl() + `auth/email/presignup/`;

        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<EmailRequestCodeItem>(url, payload, { params: httpParams }).pipe(
            map(item => {
                return EmailRequestCodeItem.parse(item);
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPostEmailPreSignUpVerification(payload: any, params: any): Observable<EmailCodeVerifyItem> {
        let url = this.getBaseUrl() + `auth/email/presignup-verification/`;

        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<EmailCodeVerifyItem>(url, payload, { params: httpParams }).pipe(
            map(item => {
                return EmailCodeVerifyItem.parse(item);
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPostEmailResetPasswordCode(payload: any, params: any): Observable<EmailRequestCodeItem> {
        let url = this.getBaseUrl() + `auth/email/reset-password-code/`;

        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<EmailRequestCodeItem>(url, payload, { params: httpParams }).pipe(
            map(item => {
                return EmailRequestCodeItem.parse(item);
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPostEmailResetPasswordCodeVerification(payload: any, params: any): Observable<EmailRequestCodeItem> {
        let url = this.getBaseUrl() + `auth/email/reset-password-code-verification/`;

        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<EmailRequestCodeItem>(url, payload, { params: httpParams }).pipe(
            map(item => {
                return EmailRequestCodeItem.parse(item);
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPostEmailResetPassword(payload: any, params: any): Observable<any> {
        let url = this.getBaseUrl() + `auth/email/reset-password/`;

        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<any>(url, payload, { params: httpParams }).pipe(
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPostEmailSignUp(payload: any, params: any): Observable<any> {
        let url = this.getBaseUrl() + `auth/email/signup/`;

        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<any>(url, payload, { params: httpParams }).pipe(
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPostFastEmailSignUp(payload: any, params: any): Observable<any> {
        let url = this.getBaseUrl() + `auth/email/fast-signup/`;

        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<any>(url, payload, { params: httpParams }).pipe(
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPostRefreshToken(payload: any, params: any): Observable<any> {
        let url = this.getBaseUrl() + `auth/token/`;

        payload['grant_type'] = 'refresh_token';
        payload['client_id'] = this.getClientID();
        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<AuthCredential>(url, payload, { params: httpParams }).pipe(
            map(item => {
                
                return AuthCredential.parse(item);
            }),
            catchError(this.errorHandler.handleError),
        );
    }
    // firebase
    public apiPostFirebaseAuth(payload:any, params:any): Observable<any> {
        let url = this.getBaseUrl() + `firebase/login/`;

        payload['client_id'] = this.getClientID();
        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<AuthCredential>(url, payload, { params: httpParams }).pipe(
            map(item => {
                
                return AuthCredential.parse(item);
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPostFirebaseInlineLink(payload:any, params:any): Observable<ExternalUser> {
        let url = this.getBaseUrl() + `firebase/inline-link/`;

        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<ExternalUser>(url, payload, { params: httpParams }).pipe(
            map(item => {
                
                return ExternalUser.parse(item);
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPostFirebaseCustomToken(payload:any, params:any): Observable<any> {
        let url = this.getBaseUrl() + `firebase/custom-token/`;

        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<any>(url, payload, { params: httpParams }).pipe(
            catchError(this.errorHandler.handleError),
        );
    }

}

