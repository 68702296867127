/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class DataStoreService {

  constructor() {
  }
  set(key: string, value: any) {
    try {
      localStorage.setItem(key, value);
      return true;
    } catch (reason) {
      return false;
    }
  }

  get(key: string) {
    try {
      const result = localStorage.getItem(key);
      if (result != null) {
        return result;
      }
      return null;
    } catch (reason) {
      return null;
    }
  }

  setObject(key: string, obj: object) {
    try {
      this.set(key, JSON.stringify(obj));
      return true;
    } catch (reason) {
      return false;
    }
  }

  getObject(key: string) {
    
    try {
      const result = this.get(key);
      if (result != null) {
        return JSON.parse(result);
      }
      return null;

    } catch (reason) {
      return null;
    }
  }

  remove(key: string){
    return localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}
