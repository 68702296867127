import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable } from "rxjs";
import { RestApiService } from "./rest-api.service";
import { Company, Paging, Site, User } from "../models/base.model";

@Injectable({
    providedIn: 'root'
})
export class ApiMeService extends RestApiService {

    public apiGetMe(params: any): Observable<User> {
        let url = this.getBaseUrl() + `me/`;
        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.get<User>(url, { params: httpParams }).pipe(
            map(item => {

                return User.parse(item);
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPostMe(payload, params): Observable<User | any> {

        const url = this.getBaseUrl() + 'me/';
        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<User>(url, payload, { params: httpParams }).pipe(
            map(item => {

                return User.parse(item);
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiPostPassword(payload, params): Observable<any> {
        /*
        * To get auth user's company-stores
        */
        let url = this.getBaseUrl() + 'me/password/';
        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });

        return this.http.post<User>(url, payload, { params: httpParams }).pipe(
            map(item => {
                return User.parse(item);
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiDeleteMe(payload, params): Observable<any> {
        const url = this.getBaseUrl() + 'me/delete';
        let httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.post<any>(url, payload, { params: httpParams }).pipe(
            catchError(this.errorHandler.handleError),
        );
    }

    public apiGetMyExhibitors(params: any): Observable<any> {
        const url = this.getBaseUrl() + 'me/sites/';
        const httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.get<any>(url, { params: httpParams }).pipe(
            map(item => {
                let page: Paging = null;
                if (item.page) {
                    page = Paging.parse(item.page);
                }

                let data = item.data.map((inItem: any) => Site.parse(inItem));

                return { 'data': data, 'page': page }
            }),
            catchError(this.errorHandler.handleError),
        );
    }

    public apiGetMyCompanies(params: any): Observable<any> {
        const url = this.getBaseUrl() + 'me/companies/';
        const httpParams: HttpParams = new HttpParams({
            fromObject: params
        });
        return this.http.get<any>(url, { params: httpParams }).pipe(
            map(item => {
                let page: Paging = null;
                if (item.page) {
                    page = Paging.parse(item.page);
                }

                let data = item.data.map((inItem: any) => Company.parse(inItem));

                return { 'data': data, 'page': page }
            }),
            catchError(this.errorHandler.handleError),
        );
    }
}
