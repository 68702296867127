import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, TimeoutError, throwError } from 'rxjs';

import { environment } from 'environments/environment';
import { ApiError } from '../models/base.model';

class ErrorHandlerProvider {

  constructor() {
  }

  public handleError(error: HttpErrorResponse | any): Observable<ApiError | any> {
    try {
      let err: ApiError;
      console.error('Error : ', error);

      if (error && error.error && error.error.error) {
        const temp = error.error.error;
        let message = `${temp.code} | ${temp.description} | ${temp.error_code}`;
        err = new ApiError(message, temp.code, temp.description, temp.error_code ? temp.error_code : '', error);
        // err = {
        //   code: temp.code,
        //   description: temp.description,
        //   code_r: temp.error_code ? temp.error_code : ''
        // };
      } else {

        err = new ApiError('Uncommon error, no internet?', error.status, error.statusText, `HTTP_ERROR_${error.status}`, error);
        // err = {
        //   code: error.status,
        //   description: error.statusText,
        //   code_r: `HTTP_ERROR_CODE_${error.status}`
        // };

      }
      // console.log('Define error : ', err);
      return throwError(() => err);
    } catch (catchError) {
      let err = new ApiError('Unknown error while constructing error', error.status, error.statusText, `CATCH_ERROR_${error.status}`, error);
      return throwError(() => err);
    }
  }

  public managerErrorHandler(rawErrorData) {

    let error;
    if (rawErrorData instanceof TimeoutError) {
      error = {
        code: 0,
        desc: 'Timeout.'
      };
    } else {
      error = rawErrorData;
    }

    return error;
  }
}

@Injectable()
export class RestApiService {
  errorHandler: ErrorHandlerProvider = new ErrorHandlerProvider();
  constructor(public http: HttpClient) {
    // if(environment.production) {
    //   this.hostingType = HOSTINGTYPE.production;
    // } else {
    //   this.hostingType = HOSTINGTYPE.staging;
    // }
    
  }

  // *********** Internal Method  **************

  protected getBaseUrl(): string {
    return environment.apiHostUrl;
  }

  protected getClientID(): string | null | undefined {
    try {
      return environment.auth.clientID;
    } catch (error) {
      return null;
    }
  }

}
