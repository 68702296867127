import { RawJsonDict, TheApp, User } from "./base.model";

export class EmailVerifyItem {
    email: string;
    hasPassword: boolean = false;
    exists: boolean = false;

    static parse(data: any) {
        let obj = new EmailVerifyItem();
        obj.email = data.email;
        obj.hasPassword = data.has_password;
        obj.exists = data.exists;

        return obj;
    }
}

export class EmailRequestCodeItem {
    email: string;
    exists: boolean = false;

    static parse(data: any) {
        let obj = new EmailRequestCodeItem();
        obj.email = data.email;

        obj.exists = data.exists;

        return obj;
    }
}

export class EmailCodeVerifyItem {
    email: string;
    code: string;
    valid: boolean = false;

    static parse(data: any) {
        let obj = new EmailCodeVerifyItem();
        obj.email = data.email;
        obj.code = data.code;
        obj.valid = data.valid;

        return obj;
    }
}

export class ExternalUser extends RawJsonDict {
    id: number;
    app: TheApp;
    user: User;
    platform: string;
    uid: string;
    created: Date;
    lastModified: Date;
    constructor(data = null) {
        super(data);
    }

    static parse(data: any): ExternalUser {

        let obj = new ExternalUser(data);
        obj.id = data.id;
        obj.platform = data.platform;
        obj.uid = data.uid;

        try {
            obj.app = TheApp.parse(data);
        } catch (error) {
        }

        try {
            obj.user = User.parse(obj.user);
        } catch (error) {
        }

        try {
            obj.lastModified = new Date(data.last_modified);
        } catch (error) {
        }

        return obj;
    }
}

/** Model: Auth */
export class AuthCredential extends RawJsonDict {
    username: string;
    accessToken: string;
    refreshToken: string;
    tokenType: string;
    scope: string;
    basicRole: string[] = [];
    level: number;
    expireDuration: any;
    tokenExpires: any;
    extra: any;
    exchangeToken: string;

    constructor(data: any) {
        super(data);
    }

    static parse(data: any): AuthCredential | null | undefined {
        if (!data) {
            return null;
        }

        let obj: AuthCredential = new AuthCredential(data);
        obj.accessToken = data.access_token;
        obj.refreshToken = data.refresh_token;
        obj.tokenType = data.token_type;
        obj.expireDuration = data.expires_in;
        obj.scope = data.scope;
        obj.basicRole = data.basic_roles;
        obj.level = data.level ? data.level : 0;

        obj.extra = data.extra;
        obj.exchangeToken = data.exchange_token ? data.exchange_token : '';

        if (data.expiration_ts) {
            obj.tokenExpires = data.expire_ts;
        } else {
            obj.tokenExpires = (new Date().getTime() / 1000) + data.expires_in;
            obj.rawJson['expire_ts'] = obj.tokenExpires;
        }
        return obj;

    }

    isExpire() {
        try {
            if (new Date() < new Date(this.tokenExpires * 1000)) {
                return false;
            }
            return true;
        } catch {
            return true;
        }
    }
}