import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthCredential } from '../models/auth-data.model';
import { environment } from 'environments/environment';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);

    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (authService.accessToken && !authService.isTokenExpired()) {
        if (!req.url.includes('amazonaws') && !req.url.includes('storage.googleapis') && !req.url.includes('assets')) {
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken),
            });
        }

        
    }

    if (!req.url.includes('auth/token/') && !req.url.includes('auth/logout') && !req.url.includes('auth/revoke-token')) {
        newReq = newReq.clone({
            params: req.params.set(
                "app_id",
                environment.appID
            ),
        });
    }

    // Response
    return next(newReq).pipe(
        catchError((error) => {
            // Catch "401 Unauthorized" responses
            if (!req.url.includes('auth/token/')) {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    // Sign out
                    authService.signOut();

                    // Reload the app
                    location.reload();
                }
            }


            return throwError(() => error);
        }),
    );
};
