import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { ApiAuthService } from '../apis/api-auth.service';
import { AuthCredential } from '../models/auth-data.model';
import { DataStoreService } from '../datas/data-store.service';
import { Auth, signInWithCustomToken } from '@angular/fire/auth';
import { UserDataService } from '../datas/user-data';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _apiAuthSvc = inject(ApiAuthService);
    private _dataStoreSvc = inject(DataStoreService);
    private _userDataSvc = inject(UserDataService);
    private auth: Auth = inject(Auth);

    private readonly KeyAuthCredential = 'auth:credential';
    private authCredential: AuthCredential;

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
    }

    get accessToken(): string {
        const authCred = this.getAuthCredential();
        if (authCred) {
            return this.authCredential.accessToken;
        }
        return null;
    }

    setAuthCredential(cred: AuthCredential) {
        this.authCredential = cred;
        this._dataStoreSvc.setObject(this.KeyAuthCredential, cred.rawJson);
    }

    getAuthCredential(): AuthCredential {

        if (!this.authCredential) {
            const json = this._dataStoreSvc.getObject(this.KeyAuthCredential);
            if (json) {
                this.authCredential = AuthCredential.parse(json);
            }
        }

        return this.authCredential;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    isTokenExpired() {
        let expire = true;
        if (this.authCredential) {
            expire = this.authCredential.isExpire();
        }
        console.log('expire:', expire);
        return expire;
    }


    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        const payload = {
            email: email
        }

        return this._apiAuthSvc.apiPostEmailResetPasswordCode(payload, {});
    }

    forgotPasswordVerifyCode(email: string, code: string): Observable<any> {
        const payload = {
            email: email,
            code: code
        }

        return this._apiAuthSvc.apiPostEmailResetPasswordCodeVerification(payload, {});
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(email: string, code: string, password: string): Observable<any> {
        const payload = {
            email: email,
            new_password: password,
            code: code,
            captcha: ''
        }
        return this._apiAuthSvc.apiPostEmailResetPassword(payload, {});
    }


    /**
     * Sign in via Email
     *
     */
    signInUsingEmail(email: string, password: string): Observable<any> {
        if (this._authenticated) {
            return throwError(() => 'User is already logged in.');
        }

        const payload = {
            username: email,
            username_type: 'email',
            password: password,
            captcha: '',
            hw_id: '',
        }

        return this._apiAuthSvc.apiPostEmailLogin(payload, {}).pipe(
            switchMap((auth: AuthCredential) => {
                // Store the access token in the local storage
                this.authCredential = auth;
                this.setAuthCredential(auth);

                this.accessToken = auth.accessToken;
                // Set the authenticated flag to true
                this._authenticated = true;

                // Return a new observable with the response
                return of(auth);
            }),
        );
    }

    /**
     * Sign in using the Firebase custom token
     */
    signInUsingFirebaseCustomToken(payload: any, params: any) {
        this._apiAuthSvc.apiPostFirebaseCustomToken(payload, params).subscribe({
            next: res => {
                let customToken = res['custom_token'];
                signInWithCustomToken(this.auth, customToken)
                    .then((userCredential) => {
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;

                        console.error('signInWithCustomToken errorCode:', errorCode);
                        console.error('signInWithCustomToken errorMessage:', errorMessage);

                    });
            },
            complete: () => {

            },
            error: err => {
                console.error('firebaseCustomLogin error:', err);
            }
        })
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        console.log('this.accessToken:', this.accessToken);
        console.log('this.authCredential:', this.authCredential);
        if (this.accessToken) {
            const logoutPayload = {
                'token': `Bearer ${this.accessToken}`
            }

            this._apiAuthSvc.apiPostLogout(logoutPayload, {}).subscribe({
                next: res=>{

                },
                complete: ()=>{

                },
                error: err=> {
                    console.error('logout error:', err);
                }
            });
        }

        this._dataStoreSvc.remove(this.KeyAuthCredential);
        // Set the authenticated flag to false
        this._authenticated = false;
        this.authCredential = null;
        this._userDataSvc.clearStorage();
        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {

        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (this.isTokenExpired()) {
            return of(false);
        }

        this.accessToken = this.authCredential.accessToken
        this._authenticated = true;

        // If the access token exists, and it didn't expire, sign in using it
        return of(true);
    }
}
