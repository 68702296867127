import { Catalog, Company, Industry, MediaObject, Project, RawJsonDict, Tag, User } from "./base.model";

/** Model: AbnormalCase */
export class AbnormalCase extends RawJsonDict {
    id: number;
    uuid: string;
    title: string; //job name
    description: string;
    type: string;
    status: string;
    galleries: MediaObject[];

    owner: User;
    user: User; //responsible user in foreign key, pending
    project: Project;
    company: Company;

    hashtags: Tag[];

    meta: any;
    statMeta: any;
    created: Date;
    lastModified: Date;

    //from meta value
    caseNo: string;
    fo: string;
    model: string;
    modelList: string;
    productionLine: string;
    rootCause: string;
    reportedBy: string;
    reportedTs: number;
    responseResetCount: number;
    reportedDate: Date;
    responsibleBy: string;

    responseTs: number;
    responseDate: Date;

    abnormalSeconds: number;

    completedTs: number;
    completedDate: Date;

    static parse(data: any): AbnormalCase {
        let instance = new AbnormalCase(data);
        instance.parseData(data);
        return instance;
    }

    parseData(data: any) {
        this.id = data.id;
        this.uuid = data.uuid;
        this.title = data.title;
        this.description = data.description;
        this.type = data.type;
        this.status = data.status;

        this.meta = data.meta ? data.meta : {};
        this.statMeta = data.stat_meta ? data.stat_meta : {};

        if (data.meta) {
            this.caseNo = data.meta.case_no;
            this.fo = data.meta.fo;
            this.model = data.meta.model;
            this.modelList = data.meta.model_list;
            this.productionLine = data.meta.production_line;
            this.rootCause = data.meta.root_cause;
            this.reportedBy = data.meta.reported_by;
            this.reportedTs = 0;
            if (data.meta.reported_ts && data.meta.reported_ts > 0) {
                this.reportedTs = data.meta.reported_ts * 1000;
                this.reportedDate = new Date(this.reportedTs);
            }

            this.responseResetCount = data.meta.response_reset_ct ? data.meta.response_reset_ct: 0;
            this.responsibleBy = data.meta.responsible_by;

            this.responseTs = 0;
            if (data.meta.response_ts && data.meta.response_ts > 0) {
                this.responseTs = data.meta.response_ts * 1000;
                this.responseDate = new Date(this.responseTs);
            }

            this.abnormalSeconds = data.meta.abnormal_seconds ? data.meta.abnormal_seconds * 1000 : 0;

            if (data.meta.completed_ts && data.meta.completed_ts > 0) {
                this.completedTs = data.meta.completed_ts * 1000;
                this.completedDate = new Date(this.completedTs);
            }
        }

        try {
            this.user = User.parse(data.company);
        } catch (error) { }

        try {
            this.company = Company.parse(data.company);
        } catch (error) { }

        try {
            this.project = Project.parse(data.project);
        } catch (error) { }


        try {
            this.galleries = data.galleries.map(x => MediaObject.parse(x));
        } catch (error) { }

        try {
            this.hashtags = data.hashtags.map(x => Tag.parse(x));
        } catch (error) { }

        this.created = new Date(data.created);

        this.lastModified = new Date(data.last_modified);

    }

    getTypeLocale() {
        let text = this.type;

        switch (this.type) {
            case 'matl-shortage':
                text = 's.abnormalType.MaterialShortage'
                break;
            case 'design-issue':
                text = 's.abnormalType.DesignIssue'
                break;
            case 'equip-breakdown':
                text = 's.abnormalType.EquipBreakdown'
                break;
            case 'quality-issue':
                text = 's.abnormalType.QualityIssue'
                break;
            case 'production':
                text = 's.abnormalType.Production'
                break;
            case 'other-issue':
                text = 's.abnormalType.OthersIssue'
                break;
            default:
                break;
        }

        return text;
    }

    getStatusLocale() {
        let locale = this.status;
        switch (this.status) {
            case 'pending':
                locale = 's.status.pending'
                break;
            case 'progress':
                locale = 's.status.progress'
                break;
            case 'complete':
                locale = 's.status.complete'
                break;
            default:
                break;
        }
        return locale;
    }

    getResponseTimeMinute() {
        if (this.responseTs > 0) {
            let diff = this.responseTs - this.reportedTs;
            const minutes = Math.floor(diff / 1000 / 60);
            return Math.floor(minutes);
        } else {
            return 0;
        }
    }

    getAbnormalHours() {

        return AbnormalCase.getAbnormalHours(this.abnormalSeconds);
    }

    static getAbnormalTimeSeconds(reportedDate: Date, completedDate: Date) {
        console.log('reportedDate:', reportedDate);
        console.log('completedDate:', completedDate);

        const startHour = 8; // 8am
        const endHour = 17; // 5pm
        const lunchStartHour = 12; // 12pm
        const lunchEndHour = 13; // 1pm

        // Convert dates to timestamps
        const startTime = reportedDate.getTime();
        const endTime = completedDate.getTime();
        console.log('s-e ts:', startTime, endTime);

        // Calculate total seconds between two dates
        let totalSeconds = (endTime - startTime) / 1000;

        console.log('totalSeconds:', totalSeconds);

        // Adjust for daily working hours and lunch break
        const daysDifference = Math.ceil(totalSeconds / (24 * 3600)); // Number of full days
        console.log('daysDifference:', daysDifference);
        let finalSeconds = 0;

        let startOfDay = new Date(reportedDate);
        while (startOfDay < completedDate) {
            let endOfDay = new Date(startOfDay);
            endOfDay.setHours(endHour, 0, 0, 0);

            if (endOfDay > completedDate) {
                endOfDay = new Date(completedDate);
            }
            console.log('startOfDay:', startOfDay);
            console.log('endOfDay:', endOfDay);

            if (startOfDay <= endOfDay) {
                
                let dailyLunchStartHours = new Date(startOfDay);
                dailyLunchStartHours.setHours(lunchStartHour, 0, 0, 0);

                let dailyLunchEndHours = new Date(startOfDay);
                dailyLunchEndHours.setHours(lunchEndHour, 0, 0, 0);

                let todaySeconds = endOfDay.getTime() - startOfDay.getTime();
                let lunchSeconds = 0;
                if (startOfDay < dailyLunchStartHours) {
                    console.log('startOfDay -> before lunch');

                    if (endOfDay >= dailyLunchEndHours) {
                        console.log('endOfDay -> after lunch');
                        lunchSeconds = 3600 * 1000;
                    } else if (endOfDay >= dailyLunchStartHours && endOfDay < dailyLunchEndHours) {
                        console.log('endOfDay -> within lunch');
                        lunchSeconds = endOfDay.getTime() - dailyLunchStartHours.getTime();
                    }
                } else if (startOfDay >= dailyLunchStartHours && startOfDay < dailyLunchEndHours) {
                    console.log('startOfDay -> within lunch');

                    if (endOfDay >= dailyLunchEndHours) {
                        console.log('endOfDay -> after lunch');
                        lunchSeconds = dailyLunchEndHours.getTime() - startOfDay.getTime();
                    } else {
                        console.log('endOfDay -> before or within lunch');
                        todaySeconds = 0;
                        lunchSeconds = 0;
                    }
                } else {
                    console.log('start of day is more than lunch time')
                    lunchSeconds = 0;
                }

                todaySeconds -= lunchSeconds;

                finalSeconds += todaySeconds;
            }


            startOfDay.setDate(startOfDay.getDate() + 1);
            startOfDay.setHours(startHour, 0, 0, 0);

        }
        console.log('finalSeconds:', finalSeconds);
        return finalSeconds >= 0 ? finalSeconds: 0;
    }

    static getAbnormalHours(seconds: number) {
        if (seconds > 0) {
            // let seconds = this.abnormalSeconds / 1000;

            // let h = Math.floor(seconds / 3600);
            // let m = Math.floor((seconds % 3600) / 60);
            // let s = seconds % 60;
            // console.log(h, m , s);

            return seconds / 1000 / 60 / 60;
        }

        return 0;
    }

}

export class ProductionOutput extends RawJsonDict {
    id: number;
    uuid: string;
    slug: string;
    title: string;
    description: string;
    summary: string;
    publishStatus: string;
    visibility: string;
    type: string;
    ownerType: string;
    coverPhoto: MediaObject;
    galleries: MediaObject[];
    attachments: MediaObject[];
    user: User;
    project: Project;
    company: Company;
    hashtags: Tag[];
    industries: Industry[];
    meta: any;
    created: Date;
    lastModified: Date;

    //meta value
    wo: string;
    fo: string;
    model: string;
    status: string;
    completeTs: number;
    completeDate: Date;

    pcl01Status: string;
    pcl02Status: string;
    pcl03Status: string;

    pcl01CompleteTs: number;
    pcl02CompleteTs: number;
    pcl03CompleteTs: number;

    pcl01CompleteDate: Date;
    pcl02CompleteDate: Date;
    pcl03CompleteDate: Date;

    static parse(data: any): ProductionOutput {
        let instance = new ProductionOutput(data);

        instance.id = data.id;
        instance.uuid = data.uuid;
        instance.slug = data.slug;
        instance.title = data.title;
        instance.description = data.description;
        instance.summary = data.summary;
        instance.publishStatus = data.publish_status;
        instance.visibility = data.visibility;
        instance.type = data.type;
        instance.ownerType = data.owner_type ? data.owner_type : '';
        instance.meta = data.meta ? data.meta : {};

        if (data.meta) {
            instance.wo = data.meta.wo;
            instance.fo = data.meta.fo;
            instance.model = data.meta.model;
            instance.status = data.meta.status;

            instance.completeTs = 0;
            if (data.meta.complete_ts && data.meta.complete_ts > 0) {
                instance.completeTs = data.meta.complete_ts;
                instance.completeDate = new Date(instance.completeTs);
            }

            instance.pcl01Status = data.meta.pcl01_status;
            instance.pcl02Status = data.meta.pcl02_status;
            instance.pcl03Status = data.meta.pcl03_status;

            instance.pcl01CompleteTs = 0;
            instance.pcl02CompleteTs = 0;
            instance.pcl03CompleteTs = 0;

            if (data.meta.pcl01_complete_ts && data.meta.pcl01_complete_ts > 0) {
                instance.pcl01CompleteTs = data.meta.pcl01_complete_ts * 1000;
                instance.pcl01CompleteDate = new Date(instance.pcl01CompleteTs);
            }

            if (data.meta.pcl02_complete_ts && data.meta.pcl02_complete_ts > 0) {
                instance.pcl02CompleteTs = data.meta.pcl02_complete_ts * 1000;
                instance.pcl02CompleteDate = new Date(instance.pcl02CompleteTs);
            }

            if (data.meta.pcl03_complete_ts && data.meta.pcl03_complete_ts > 0) {
                instance.pcl03CompleteTs = data.meta.pcl03_complete_ts * 1000;
                instance.pcl03CompleteDate = new Date(instance.pcl03CompleteTs);
            }
        }

        try {
            instance.user = User.parse(data.user);
        } catch (error) { }

        try {
            instance.company = Company.parse(data.company);
        } catch (error) { }

        try {
            instance.project = Project.parse(data.project);
        } catch (error) { }

        try {
            instance.coverPhoto = MediaObject.parse(data.cover_photo);
        } catch (error) { }


        try {
            instance.galleries = data.galleries.map(x => MediaObject.parse(x));
        } catch (error) { }

        try {
            instance.attachments = data.attachments.map(x => MediaObject.parse(x));
        } catch (error) { }

        try {
            instance.hashtags = data.hashtags.map(x => Tag.parse(x));
        } catch (error) { }

        try {
            instance.industries = data.industries.map(x => Industry.parse(x));
        } catch (error) { }

        instance.created = new Date(data.created);

        instance.lastModified = new Date(data.last_modified);

        return instance;
    }

    getStatusLocale() {
        let locale = 'unknown';
        switch (this.status) {
            case 'pending':
                locale = 's.status.pending'
                break;
            case 'progress':
                locale = 's.status.progress'
                break;
            case 'complete':
                locale = 's.status.complete'
                break;
            default:
                break;
        }
        return locale;
    }

    getPcl01StatusLocale() {
        let locale = 'unknown';
        switch (this.pcl01Status) {
            case 'pending':
                locale = 's.status.pending'
                break;
            case 'progress':
                locale = 's.status.progress'
                break;
            case 'complete':
                locale = 's.status.complete'
                break;
            default:
                break;
        }
        return locale;
    }

    getPcl02StatusLocale() {
        let locale = 'unknown';
        switch (this.pcl02Status) {
            case 'pending':
                locale = 's.status.pending'
                break;
            case 'progress':
                locale = 's.status.progress'
                break;
            case 'complete':
                locale = 's.status.complete'
                break;
            default:
                break;
        }
        return locale;
    }

    getPcl03StatusLocale() {
        let locale = 'unknown';
        switch (this.pcl03Status) {
            case 'pending':
                locale = 's.status.pending'
                break;
            case 'progress':
                locale = 's.status.progress'
                break;
            case 'complete':
                locale = 's.status.complete'
                break;
            default:
                break;
        }
        return locale;
    }
}

export class AbnormalResponsibleObject {

    data: PIC[] = [];

    static parse(data: any): AbnormalResponsibleObject {
        let instance = new AbnormalResponsibleObject();
        
        instance.data = data;

        return instance;
    }

    getByKey(key: string) {
        return this.data.find(x=>x.value == key);
    }

    getNameByKey(key: string) {
        let obj = this.getByKey(key);

        return obj ? obj.name : null;
    }

}

export interface PIC {
    name: string;
    value: string;
    _edit: boolean
}